import React, { Component } from 'react'

export class Card1 extends Component {
  render() {
    const {img, title, description} = this.props;
    return (
        <div className="card-1">
            <img  src={img} alt={title} />
            <div>
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
        </div>
    )
  }
}

export default Card1