import React, { Component } from 'react'

export class BottomSvg extends Component {
  render() {
    return (
        <svg
        className='svgg'
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 24 24"
 
  >
    <path
     className='svgg'
      fill="#000"
      d="m12 14.586 6.293-6.293a1 1 0 1 1 1.414 1.414l-6.646 6.647a1.5 1.5 0 0 1-2.122 0L4.293 9.707a1 1 0 0 1 1.414-1.414L12 14.586z"
    />
  </svg>
    )
  }
}

export default BottomSvg
