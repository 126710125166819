import React, { Component } from 'react'
import CalendarSvg from '../svgs/CalendarSvg'
import EyeSvg from '../svgs/EyeSvg'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
export class BlogCard extends Component {
  // public-blog-read'
  addCountBtn(id){
    // this.props.insertNewData('public-blog-read',{id:id})
  }
  getTextFromHTML(text){
    var parser = new DOMParser();
  var doc = parser.parseFromString(text, 'text/html');
    return doc.children[0].children[1].textContent;
}
  render() {
    const {data} = this.props;
    return (
      <div className='blog_card-container'>
            <div className='blog_card-img'>
                <img src={data?.image_full_url} alt={data?.title} />
            </div>
            <div className='blog_card_body'>
                <span className='blog_card_date'>
                    <CalendarSvg />
                    {moment(data?.created_at).format("MMMM Do YYYY")}
                </span>
                <h4>{data?.title}</h4>
                <span className='blog_card_user'>
                    Admin 
                    <span>
                        <EyeSvg />
                        {data?.read_count}
                    </span>
                </span>
                {
                  data?.description?
                <p  className='blog_card_text'>{this.getTextFromHTML(data?.description).substring(0, 200)}</p>
                  :null
                }
                <Link onClick={this.addCountBtn.bind(this, data?.id)} to={`/blog/${data?.path}`} className='blog_card_btn'>Ardını oxu</Link>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(BlogCard)