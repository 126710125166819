import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class TrainerCard extends Component {
  render() {
    const {data} = this.props;
    return (
      <Link to={`/trainer/${data?.id}`} className='trainer_card2'>
        <img src={data?.image_full_url} />
        <div className='trainer_card_footer2'>
            <h3>{data?.name}</h3>
            <span>{data?.position}</span>
        </div>
      </Link>
    )
  }
}

export default TrainerCard
