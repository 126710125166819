import React, { Component } from 'react'
import TrainingQuestionCard from '../cards/TrainingQuestionCard'

export class TrainingQuestions extends Component {
  state={
    openCard:999
  }
  render() {
    const {data} = this.props;
    const {openCard} = this.state;
    return (
      <section>
            <div className='container mt-3'>
              {
                data.map((item, i)=>{
                  return(
                    <TrainingQuestionCard index={i} changeVisible={(index)=>this.setState({openCard:index})} visible={openCard==i} item={item} key={i} />
                  )
                })
              }
            </div>
      </section>
    )
  }
}

export default TrainingQuestions
