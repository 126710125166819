import React, { Component } from 'react'
import {  useParams } from 'react-router-dom'
import { configRedux } from '../redux/ConfigRedux'
import { getTrainerData, getTrainerDocument } from '../actions/MainAction'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import CertCard from '../components/cards/CertCard'
import SectionHeader from '../components/SectionHeader'
import {Helmet} from "react-helmet";
function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class SingleTrainerPage extends Component {

    componentDidMount(){
        this.props.dispatch(getTrainerData(this.props.params.id))
        this.props.dispatch(getTrainerDocument(this.props.params.id))
    }
     
  render() {
    const {trainerData, trainerDocument} = this.props;
    return (
      <div >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Müəllim Haqqında - Nicode Academy</title>
          <meta name="description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta name="keywords" content="Nicode Academy, Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI" />
          <link rel="canonical" href="https://nicode.az/"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content={`${trainerData?.title} - Nicode Academy`}/>
          <meta property="og:title" content={`${trainerData?.title} - Nicode Academy`}/>
          <meta property="og:url" content="https://nicode.az/trainings"/>
          <meta property="og:site_name" content="https://nicode.az/trainings"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az/trainings"/>
          <meta property="og:title" content={`${trainerData?.title} - Nicode Academy`}/>
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
        </Helmet>
         <PageHeaderSection title={"Müəllim məlumatları"} />
        {
            trainerData != ''?
            <section>
                <div className='container mt-3'>
                    <div className='single_trainer_header'>
                        <img alt={trainerData?.name} src={trainerData?.image_full_url} />
                       <div>
                            <h2>{trainerData?.name}</h2>
                            <p dangerouslySetInnerHTML={{ __html:  trainerData?.description}}></p>
                       </div>
                    </div>
                </div>
            </section>:null
        }
        <section>
          <div className='container mt-5 trainer_cert'>
            {
              trainerDocument?.length != 0?
               <SectionHeader title={"Sertifikatlar"} />:null
            }
           
            <div className="grid-col-3">
              {
                trainerDocument?.map((data, i)=>{
                  return(
                    <CertCard key={i} data={data} />
                  )
                })
              }
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default configRedux(withHooks(SingleTrainerPage), ["trainerData", "trainerDocument"])
