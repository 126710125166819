import axios from "axios";
import { MAIN_API } from "../MAIN_API";
import { changeStateValue, hideLoader, setLoader } from "../redux/MainReducer";


export const getAbout=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-about`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'aboutData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getWhyUs=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-why-us`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'whyUs',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainings=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-training`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'trainings',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getFaqs=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-faq`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'faqs',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getStudentsReturn=(data)=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-student-return`, {
        params: {...data}
    })
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'studentReturns',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainingData=(id)=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-training-data/${id}?type=name`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'trainingData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainerDocument=(id)=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-trainer-documents?id=${id}`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'trainerDocument',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getBlogs=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-blog?type=name`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'blogs',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getBlogData=(id)=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-blog-data/${id}?type=name`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'blogData',
            value: resp.data
        }))
        // console.log(resp.data)
        return "success"
    }).catch(err=>{
        console.log(err.response)
        return "error"
    })
}
export const getTrainerData=(id)=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-trainer-data/${id}`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'trainerData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainers=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-trainer`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'trainers',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getHeaderImages=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-header-images`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'headerImages',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getEvents=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-events`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'eventsData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getOneEvents=(id)=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-events/${id}`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'eventsOneData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getApplyOptions=()=>async dispatch => {
    dispatch(setLoader());
    return await axios.get(`${MAIN_API}/public-apply-option`)
    .then(resp=>{
        dispatch(changeStateValue({
            name: 'publicOption',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}

export const insertNewData=(url, data) => async dispatch => {
    // console.log(url, data)
    dispatch(setLoader());
    return await axios.post(`${MAIN_API}/${url}`, data)
    .then(resp=>{
        dispatch(hideLoader());
        return "success";
    }).catch(err=>{
        console.log(err.response)
        dispatch(hideLoader());
        return "error";
    })
}