import React, { Component } from 'react'

export class HandSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        id="katman_1"
        x={0}
        y={0}
        style={{
          enableBackground: "new 0 0 600 450",
        }}
        viewBox="0 0 600 450"
        width={40}
        height={40}
      >
        <style>{".st0{fill:#010202}"}</style>
        <symbol id="Icon--hand-clicking" viewBox="-10.3 -12.9 20.6 25.8">
          <path
            d="M2.1 12.9c.1-.1.2-.1.4 0h-.4zM3.1 12.9h.1-.1zM3.2 12.9s0-.1 0 0c0-.1.1-.1 0 0zM.5-6.4v-2.4c0-1.3-.6-2.3-1.8-2.9-1.9-.9-4.2.5-4.3 2.6-.1.9 0 1.8 0 2.7-.9-.8-1.3-3-.2-4.7 1.2-1.8 3.8-2.3 5.6-1 1.8 1.3 2.1 3.7.7 5.7z"
            className="st0"
          />
          <path
            d="M10.3-.1c0-1.6-1.9-2.6-3.3-1.8-.3.1-.3.1-.3-.1-.1-1.1-1.2-2.1-2.3-2-.5 0-1 .2-1.4.5-.3-.6-.8-1.1-1.4-1.3-.7-.2-1.4-.1-2 .4V-9c-.1-1.6-1.8-2.5-3.2-1.8-.9.4-1.3 1.2-1.3 2.1v7.8c-.1-.2-.2-.3-.3-.4-.6-.5-1.2-1-1.9-1.3-.4-.2-.8-.4-1.2-.5-1.2-.2-2.1.8-2 1.7v.2c0 .4.2.8.4 1.2.6 1.3 1.4 2.5 2.2 3.6.8 1.2 1.6 2.4 2.3 3.7.7 1.2 1.4 2.4 2.4 3.3.8.8 1.8 1.4 2.9 1.8.6.2 1.3.4 2 .4H3.6c.2-.1.5-.1.7-.1 1.4-.3 2.7-.9 3.8-1.9 1.4-1.4 2.3-3 2.4-5-.2-2-.2-3.9-.2-5.9zM7.8 9.5c-1 1.2-2.2 2-3.8 2.3-2.6.5-4.9-.2-6.7-2.2-.7-.7-1.2-1.5-1.7-2.3C-5.6 5.2-7 3.1-8.3 1c-.3-.6-.7-1.2-1-2-.1-.4-.2-.7.1-1 .3-.3.7-.3 1-.1.9.2 1.5.8 2.2 1.3.6.5 1.3 1.1 1.9 1.8l.2.2v-9.9c0-.8.6-1.3 1.1-1.4.7-.1 1.4.4 1.5 1.1V.2c0 .2.1.3.3.3.2 0 .5.1.6 0 .1-.1 0-.4 0-.6v-2.4c0-.5.2-1 .6-1.2.5-.3.9-.4 1.3-.1.5.2.8.6.8 1.1v2c0 .2 0 .3.2.2.2 0 .5.1.6 0 .1-.1 0-.4 0-.6v-.5c0-.8.6-1.4 1.3-1.4.7 0 1.3.6 1.3 1.4V.3c0 .2 0 .2.2.2s.5.1.6 0c.2-.1 0-.4.1-.6.1-.6.7-1.2 1.3-1.2.6 0 1.2.6 1.3 1.2V5.2c.2 1.6-.3 3-1.4 4.3z"
            className="st0"
          />
        </symbol>
        <use
          xlinkHref="#Icon--hand-clicking"
          width={30}
          height={30}
          x={-10.3}
          y={-12.9}
          
          style={{
            overflow: "visible",
          }}
          transform="translate(300 225) scale(11.8964)"
        />
      </svg>
    )
  }
}

export default HandSvg
