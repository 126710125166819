import React, { Component } from 'react'
import logo from "../img/nicode2.png"
import logoBlue from "../img/nicode1.png"
import { Link } from 'react-router-dom'
import { configRedux } from '../redux/ConfigRedux'
import { getTrainings } from '../actions/MainAction'
import MenuOpenSvg from './svgs/MenuOpenSvg'
import MenuCloseSvg from './svgs/MenuCloseSvg'
import BottomSvg from './svgs/BottomSvg'
export class Header extends Component {
    state={
        contRef: React.createRef(),
        navCont: React.createRef(),
        logoWhite:true
    }
    componentDidMount(){
        this.props.dispatch(getTrainings());
        window.addEventListener('scroll', e=>{
           
            if(this.state.contRef.current && this.state.contRef.current.style ){
                if(window.scrollY >=120){
                    this.setState({logoWhite:false})
                    this.state.contRef.current.style.position='fixed';
                    this.state.contRef.current.style.width='100%';
                    this.state.contRef.current.style.width='100%';
                    this.state.contRef.current.style.top='0';
                    this.state.contRef.current.style.backgroundColor='white';
                    if(window.innerWidth > 1029){
                        this.state.navCont.current.classList.add("stickyNav");
                    } 
                }else{
                    this.state.contRef.current.style.position='absolute';
                    this.state.contRef.current.style.backgroundColor='rgba(0,0,0,0)';
                    if(window.innerWidth > 1029){
                        this.state.navCont.current.classList.remove("stickyNav");
                    }
                    this.setState({logoWhite:true})
                } 
            }
            
        })
    }
    closeNav(e){
        const inp = document.querySelector('#navCheck');
        inp.checked = false;
       
        if( window.innerWidth <= 1030){
            const sub = document.querySelector('.sub-nav');
            sub.style.height = '0px';
            sub.style.padding = '0px';
        }
    }
    openSubNav(e){
       
        if((e.target.className == "drop_nav" || e.target.className == 'drop_nav_head' || e.target.className.animVal == 'svgg') && window.innerWidth <= 1030){
            const sub = document.querySelector('.sub-nav');
            if(sub.style.height == ''  || sub.style.height == '0px'){
                sub.style.height = '270px';
                // sub.style.padding = '10px';
            }else if(sub.style.height == '270px'){
                sub.style.height = '0px';
                sub.style.padding = '0px';
            }
        }
        
    }
  render() {
    const {trainings} = this.props;
    const {contRef, navCont, logoWhite} = this.state
    return (
        <header ref={contRef}>
            <div className="container flex justify-between items-center">
               <Link to="/">
                <img className="logo" src={logoWhite?logo:logoBlue} alt="logo"/>
              
               </Link>
               <input type='checkbox' id='navCheck' style={{ display: 'none' }} />
               <div onClick={this.closeNav.bind(this)} className='nav_shadow'></div>

                <nav className='' ref={navCont}>
                    <div className='resp_logo'>
                        <Link onClick={this.closeNav.bind(this)} to="/">
                            <img  src={logoBlue} />
                            
                        </Link>
                        
                    </div>
                    <ul className="flex">
                        <li><Link onClick={this.closeNav.bind(this)} to={'/'}>Ana Səhifə</Link></li>
                        <li onClick={this.openSubNav.bind(this)} className='drop_nav'><div className='drop_nav_head'><Link onClick={this.closeNav.bind(this)} to={'/trainings'}>Təlimlər</Link> <BottomSvg /></div>
                        {/* {
                            window.innerWidth >=1030? */}
                            <ul className="sub-nav">
                                {
                                    trainings?.map((data, i)=>{
                                        return(
                                            <li key={i}><Link onClick={this.closeNav.bind(this)} to={`/training/${data?.path}`}>{data?.title?.length <34?data?.title: `${data?.title?.substring(0, 33)}...`}</Link></li>
                                        )
                                    })
                                }
                            </ul>
                            {/* :null
                        } */}
                            
                        </li>
                        <li><Link onClick={this.closeNav.bind(this)} to={'/trainers'}>Müəllimlər</Link></li>
                        <li><Link onClick={this.closeNav.bind(this)} to={'/blog'}>Blog</Link></li>
                        <li><Link onClick={this.closeNav.bind(this)} to={'/events'}>Tədbirlər</Link></li>
                        <li><Link onClick={this.closeNav.bind(this)} to={'/about'}>Haqqımızda</Link></li>
                        <li><Link onClick={this.closeNav.bind(this)} to={'/contact'}>Əlaqə</Link></li>
                    </ul>
                </nav>

                <label className='openMenuBtn' htmlFor='navCheck'>
                    <MenuOpenSvg color={logoWhite? '#fff': '#0C45E6'} />
                </label>
                <label className='closeMenuBtn' htmlFor='navCheck'>
                    <MenuCloseSvg color={logoWhite? '#fff': '#0C45E6'} />
                </label>
            </div>
        </header>
    )
  }
}

export default configRedux(Header, ['trainings'])