import React, { Component } from 'react'
import { useParams } from 'react-router-dom'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import { connect } from 'react-redux';
import { getOneEvents } from '../actions/MainAction';
import CalendarEventSvg from '../components/svgs/CalendarEventSvg'
import moment from 'moment';
import MoneySvg from '../components/svgs/MoneySvg';
import AddressSvg from '../components/svgs/AddressSvg';
import DurationSvg from '../components/svgs/DurationSvg';
import FormsSvg from '../components/svgs/FormsSvg';
import LocationSvg from '../components/svgs/LocationSvg';
import { Helmet } from 'react-helmet';
function withHooks(Component){
    return props=> <Component {...props} params={useParams()} />
  }
export class SingleEventsPage extends Component {

    componentDidMount(){
        this.props.getOneEvents(this.props.params.id);
        window.scrollTo(0, 0)
    }
  render() {
    const {eventsOneData} = this.props;
    console.log(eventsOneData)
    return (
      <div>
        <Helmet>
            <meta charSet="utf-8" />
          <title>Tədbir Haqqında - Nicode Academy</title>
        </Helmet>
          <PageHeaderSection titleHtml={eventsOneData?.name} />
          <section >
            <div className="container">
                <div className="single_training_header">
                    <img src={eventsOneData?.image_full_url} alt={eventsOneData?.title} />
                    <div>
                    <div className="events_details_container">
                       
                        <div className="training_detail_card">
                            <div className="training_detail_body">
                                <h3><CalendarEventSvg /> Tarix: </h3>
                                <span>{moment(eventsOneData?.date).format("DD.MM.YYYY")}</span>
                            </div>
                        </div>
                        <div className="training_detail_card">
                            <div className="training_detail_body">
                                <h3 style={{ marginTop: '-4px' }}><MoneySvg /> Ödəniş:</h3>
                                <span style={{ display:'block', marginTop:'-2px' }}>{eventsOneData?.price}</span>
                            </div>
                        </div>
                        <div className="training_detail_card">
                            <div className="training_detail_body">
                                <h3><AddressSvg /> Məkan:</h3>
                                <span>{eventsOneData?.location}</span>
                            </div>
                        </div>
                        <div className="training_detail_card">
                            <div className="training_detail_body">
                                <h3><DurationSvg /> Müddət:</h3>
                                <span>{eventsOneData?.duration}</span>
                            </div>
                        </div>
                      
                        <a  href={eventsOneData?.registration_url} className="training_detail_card">
                            <div className="training_detail_body">
                                <h3><FormsSvg /> Qeydiyyatdan Keç</h3>
                            </div>
                        </a>
                      {
                        eventsOneData?.location_url?
                        <a  href={eventsOneData?.location_url} className="training_detail_card">
                            <div className="training_detail_body">
                                <h3><LocationSvg /> Ünvanı xəritədə göstər</h3>
                            </div>
                        </a>:null
                      }
                        
                        
                        
                     </div>
                    </div>
                </div>
                <div className='training_data-body'>
                    <div className='mt-5'>
                        <h4 className='single_training-subtitle'>Vebinarın gedişatı</h4>
                        <p className='single_training-text' dangerouslySetInnerHTML={{ __html: eventsOneData?.description }}></p>
                    </div>
                </div>
            </div>
            </section>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    eventsOneData: state.Data.eventsOneData
});
const mapDispatchToProps = {getOneEvents}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleEventsPage))
