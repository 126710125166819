import React, { Component } from 'react'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import TrainersSection from '../components/sections/TrainersSection'
import {Helmet} from "react-helmet";
import EventsSection from '../components/sections/EventsSection';
export class EventsPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
   
  }
  render() {
  
    return (
      <div className='trainer_main_cont'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tədbirlərimiz - Nicode Academy Təlimlər, Seminarlar və Workshoplar</title>
          <meta name="description" content="Nicode Academy tərəfindən təşkil olunan təlimlər, seminarlar və workshoplarla texnologiya dünyasında biliklərinizi artırın. Data Analitika, Data Science, Python, Excel və SQL üzrə tədbirlərimizə qatılın və peşəkar inkişafınıza investisiya edin."/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content="Nicode Academy tərəfindən təşkil olunan təlimlər, seminarlar və workshoplarla texnologiya dünyasında biliklərinizi artırın. Data Analitika, Data Science, Python, Excel və SQL üzrə tədbirlərimizə qatılın və peşəkar inkişafınıza investisiya edin."/>
          <meta name="keywords" content="Nicode Academy tədbirlər, seminarlar, workshoplar, Data Analitika tədbiri, Data Science təlimi, Python workshopu, Excel seminarı, SQL təlimləri, texnologiya tədbirləri, peşəkar inkişaf tədbirləri" />
          <link rel="canonical" href="https://nicode.az/"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content="Tədbirlərimiz - Nicode Academy Təlimlər, Seminarlar və Workshoplar"/>
          <meta property="og:title" content="Tədbirlərimiz - Nicode Academy Təlimlər, Seminarlar və Workshoplar"/>
          <meta property="og:url" content="https://nicode.az/trainers"/>
          <meta property="og:site_name" content="https://nicode.az/trainers"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az/trainers"/>
          <meta property="og:title" content="Tədbirlərimiz - Nicode Academy Təlimlər, Seminarlar və Workshoplar"/>
          <meta property="og:description" content="Nicode Academy tərəfindən təşkil olunan təlimlər, seminarlar və workshoplarla texnologiya dünyasında biliklərinizi artırın. Data Analitika, Data Science, Python, Excel və SQL üzrə tədbirlərimizə qatılın və peşəkar inkişafınıza investisiya edin."/>
        </Helmet>
        <PageHeaderSection title="Tədbirlərimiz" />
        <EventsSection type="all" />
      </div>
    )
  }
}

export default EventsPage


