import React, { Component } from 'react'
import img1 from "../img/header1.png";
import img2 from "../img/header2.png";
import img3 from "../img/header3.png";
import img4 from "../img/header4.png";
import { configRedux } from '../redux/ConfigRedux'
import { getAbout, getHeaderImages } from '../actions/MainAction';
import { Link } from 'react-router-dom';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export class HeaderBox extends Component {
    componentDidMount(){
        this.props.dispatch(getAbout());
        this.props.dispatch(getHeaderImages());
    }
  render() {
    const {aboutData, headerImages} = this.props;
    // console.log(headerImages)
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 564 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 564, min: 0 },
          items: 1
        }
      };
      const defaults = [
        {
          title:'Əmək bazarında axtarılan işçi ol',
          img: img1
        },
        {
          title:'Keyfiyyətli tədrisin özün şahidi ol',
          img: img2
        },
        {
          title:'Fərqlənmək istəyənlərin ünvanıyıq',
          img: img3
        }
        ,
        {
          title:'Data science sahəsi səni gözləyir',
          img:img4
        }
      ]
    return (
        <section id='firstSection'>
            {/* <div className="container first_section-container flex justify-between items-center">
                <div className="text-Box">
                    <span>{aboutData?.first_page_sub_title}</span>
                    <h1>{aboutData?.first_page_title}</h1>
                    <p>{aboutData?.first_page_text}</p>
                    <Link to="/trainings">Explore course</Link>
                </div>
                <img  className="header-img" src={img} alt="First image" />
                
            </div> */}
            {/* <Carousel
                autoPlay={true}
                showIndicators={true}
                showThumbs={false}
                infiniteLoop={true}
                emulateTouch={true}
                showArrows={false}
                showStatus={false}
                selectedItem={0}
                > */}
                 <Carousel 
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    infiniteLoop={true}
                    emulateTouch={true}
                    containerClass="carousel-container"
                  //   deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    // showThumbs={false}
                    selectedItem={0}
                    firstItem={0}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    centerMode={false}
                    
                  >
                    {
                      headerImages.length != 0?
                        headerImages?.map((data, i)=>{
                            return(
                            <div key={i} className='header_carousel_img'>
                                <img  src={data?.image_full_url} />
                                <h1 className='header_title_main'>{data?.title}</h1>
                                <Link className='explore_btn' to="/trainings"><span>Təlimlərlə tanış olun</span></Link>
                            </div>  
                            )
                        }):
                        defaults.map((data, i)=>{
                          return(
                            <div key={i} className='header_carousel_img'>
                                <img  src={data?.img} />
                                <h1 style={{ color:'black' }} className='header_title_main'>{data?.title}</h1>
                                <Link className='explore_btn' to="/trainings"><span>Təlimlərlə tanış olun</span></Link>
                            </div>
                          )
                        })
                    }
            </Carousel>
        </section>
    )
  }
}

export default configRedux(HeaderBox, ["aboutData", "headerImages"])