import React, { Component } from 'react'
import SectionHeader from '../SectionHeader'
import Card2 from '../cards/Card2'
import { configRedux } from '../../redux/ConfigRedux'
import { getTrainings } from '../../actions/MainAction'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from '../Skeleton'
export class TrainingSection extends Component {
  componentDidMount(){
    this.props.dispatch(getTrainings())
  }
  handleDragStart = (e) => e.preventDefault();
  render() {
    const {trainings, type = "all"} = this.props;
   

    const items = [];
    {
      trainings.map((data, i) => {
        items.push(
          <Card2 
            key={i}
            data={data}
          />
        )
      })
    }
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 564 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 564, min: 0 },
        items: 1
      }
    };
    // console.log(trainings)
    return (
        <section className='training_section'>
        <div className="container mt-5">
           <SectionHeader title="Təlimlərimiz" />
           {
            type == 'all'?
            <div className="grid-col-3">
              { trainings.length !== 0?
                trainings.map((data, i) => {
                  return(
                    <Card2 
                      key={i}
                      data={data}
                    />
                  )
                }):
                <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                </>
              }
            </div>:
             <div className='carousel-cont mt-3'>
              <Carousel 
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}
                    customTransition="all 2s"
                    transitionDuration={2000}
                    containerClass="carousel-container"
                  //   deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    
                  >
                    {
                      trainings.length !== 0?
                      trainings.map((data, i) => {
                        return(
                          <Card2 
                            key={i}
                            data={data}
                          />
                        )
                      }):
                      [1, 2, 3].map((item, i)=>{
                        return(
                          <Skeleton key={i} />
                        )
                      })
                     
                      
                      
                    }
                  </Carousel>
             </div>
           }
          
            
        </div>
    </section>
    )
  }
}

export default configRedux(TrainingSection, ["trainings"])