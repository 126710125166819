import React, { Component } from 'react'

export class CloseSvg extends Component {
  render() {
    const {size=30, color="#fff"} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        
      >
        <path
          fill={color}
          d="M8.004 9.418a1 1 0 1 1 1.414-1.414l2.588 2.588 2.585-2.585a1 1 0 1 1 1.414 1.414l-2.585 2.585 2.584 2.584a1 1 0 0 1-1.414 1.414l-2.584-2.584-2.585 2.585a1 1 0 0 1-1.414-1.414l2.585-2.585-2.588-2.588Z"
        />
        <path
          fill={color}
          fillRule="evenodd"
          d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11ZM3.007 12a8.993 8.993 0 1 0 17.986 0 8.993 8.993 0 0 0-17.986 0Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default CloseSvg
