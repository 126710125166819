import React, { Component } from 'react'
import { configRedux } from '../../redux/ConfigRedux'
import { getStudentsReturn, insertNewData } from '../../actions/MainAction';
import StarSvg from '../svgs/StarSvg';
import { mixinAlert } from '../../actions/Alerts';
import LeftSvg from '../svgs/LeftSvg';
import RightSvg from '../svgs/RightSvg';

export class WhatSayStudents extends Component {
    state={
        index:0,
        first_name: '',
        last_name:'',
        email:'',
        phone_number:'',
        btnDisable:false
    }
    componentDidMount(){
        this.props.dispatch(getStudentsReturn({type: 'all'}));
        let index = this.state.index;

        setInterval(()=>{
            const {studentReturns} = this.props;
            if(index <studentReturns.length-1){
                index++
            }else{
                index=0
            }
            this.setState({index:index})
        }, 4000)
    }
    applyBtn(data, e){
        if(data?.first_name != '' && data?.last_name != '' && data?.email != '' && data?.phone_number != ''){
            this.setState({btnDisable:true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            formData.append('training_id', this.props.trainingId)
            let url = 'public-traning-apply';
            this.props.dispatch(insertNewData(url, formData))
            .then(resp=>{
                this.setState({btnDisable:false})

                this.setState({
                    first_name:'',
                    last_name:'',
                    email:'',
                    phone_number:'',
                })
                if(resp == 'success'){
                    mixinAlert('success', "Müraciətiniz göndərildi.");
                }else{
                    mixinAlert('error', "Xəta başverdi.");
                }
            })
        }else{
            mixinAlert('error', "Zəhmət olmasa bütün xanaları doldurun.");
        }
    }

    changeSliderBtn(index, type){
        if(type == 'inc'){
            if(index <this.props.studentReturns.length-1){
                index++
            }else{
                index=0
            }
        }else{
            if(index > 0){
                index--
            }else{
                index=this.props.studentReturns.length-1
            }
        }
       
        this.setState({index:index})
    }
  render() {
    const {studentReturns, studentId, apply=false} = this.props;
    const {index, first_name, last_name, email, phone_number, btnDisable} = this.state;
    return (
        <section>
            <div className="container bg mt-5 flex justify-between items-center thinking_main-container">
                <div className="thigings_text-box">
                    {
                        !apply?
                        <h2>Tələbələrimiz haqqımızda nə deyir?</h2>:
                        <>
                        <div className='grid-2-mini'>
                            <div className='inp_container'>
                                <label htmlFor='firstName'>Ad</label>
                                <input value={first_name} onChange={(e)=>this.setState({first_name:e.target.value})} type='text'id='firstName' placeholder='Ad' />
                            </div>
                            <div className='inp_container'>
                                <label htmlFor='lastName'>Soyad</label>
                                <input  value={last_name} onChange={(e)=>this.setState({last_name:e.target.value})} type='text'id='lastName' placeholder='Soyad' />
                            </div>
                            <div className='inp_container'>
                                <label htmlFor='email'>E-poçt</label>
                                <input  value={email} onChange={(e)=>this.setState({email:e.target.value})} type='email'id='email' placeholder='E-poçt' />
                            </div>
                            <div className='inp_container'>
                                <label htmlFor='phone_number'>E-poçt</label>
                                <input  value={phone_number} onChange={(e)=>this.setState({phone_number:e.target.value})} type='tel'id='phone_number' placeholder='Əlaqə nömrəsi' />
                            </div>
                        </div>
                        <button disabled={btnDisable} className='apply_button' onClick={this.applyBtn.bind(this, this.state)}>Müraciət et</button>
                        </>
                    }
                    
                    <div className='slider-btn_container'>
                        <button onClick={this.changeSliderBtn.bind(this, index, 'dec')} className='slider_btn'>
                            <LeftSvg />
                        </button>
                        <button onClick={this.changeSliderBtn.bind(this, index, 'inc')} className='slider_btn'>
                            <RightSvg />
                        </button>
                    </div>
                </div>
                <div className="thinking_slider-container2">
                    {
                        studentReturns?.map((data, i)=>{
                            if(index == i){
                                return(
                                    <div key={i} className="thinking_slider_card2">
                                        <div className="thinking_slider_header flex items-center">
                                            <img src={data?.image_full_url} alt={data?.name} />
                                            <div>
                                                <h4>{data?.name}</h4>
                                                <span>{data?.position}</span>
                                                <div className='flex items-center gap-5'>
                                                    {
                                                        [1, 2, 3, 4, 5].map((item, j)=>{
                                                            if(item<=data?.raiting){
                                                                return(
                                                                    <StarSvg key={j} />
                                                                )
                                                            }else {
                                                                return(
                                                                    <StarSvg key={j} color="#5f6160" />
                                                                )
                                                            }
                                                           
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <p>{data?.text}</p>
                                    </div>
                                )
                            }
                        })
                    }
                    
                </div>
            </div>
        </section>
    )
  }
}

export default configRedux(WhatSayStudents, ["studentReturns"])