import React, { Component } from 'react'
import logo from '../../img/nicode1.png'
import { getAbout } from '../../actions/MainAction'
import { configRedux } from '../../redux/ConfigRedux'
import FbSvg from '../svgs/FbSvg'
import WpSvg from '../svgs/WpSvg'
import PhoneSvg from '../svgs/PhoneSvg'
import InstSvg from '../svgs/InstSvg'
import InSvg from '../svgs/InSvg'
import { Link } from 'react-router-dom'
export class Footer extends Component {
    componentDidMount(){
        this.props.dispatch(getAbout())
    }
  render() {
    const {aboutData, trainings} = this.props;
    // console.log(aboutData)
    return (
      <footer>
        <div className='container'>
            <div className='flex footer-cont'>
                <div>
                    <img className='footer_logo' src={logo} />
                    <p className='footer_desc'>{aboutData?.first_page_text}</p>
                    <div className='flex gap-20 mt-3 '>
                        <div className='social_icon dark'>
                            <a target='_blank' href={`https://wa.me/${aboutData?.number}`}>
                                <WpSvg />
                            </a>
                        </div>
                        <div className='social_icon dark'>
                            <a target='_blank' href={aboutData?.facebook}>
                                <FbSvg />
                            </a>
                        </div>
                        
                        <div className='social_icon dark'>
                            <a target='_blank' href={aboutData?.instagram}>
                                <InstSvg />
                            </a>
                        </div>
                        <div className='social_icon dark'>
                            <a target='_blank' href={aboutData?.linkedin}>
                                <InSvg />
                            </a>
                        </div>
                    </div>
                </div>

               <div>
                    <h3 className='footer-sub_tit'>Nicode Academy</h3>
                    <ul className='footer_nav'>
                        <li>
                            <Link to={'/'}>Ana Səhifə</Link>
                        </li>
                        <li>
                            <Link to={'/trainings'}>Təlimlər</Link>
                        </li>
                        <li>
                            <Link to={'/trainers'}>Müəllimlər</Link>
                        </li>
                        <li>
                            <Link to={'/blog'}>Blog</Link>
                        </li>
                        <li>
                            <Link to={'/about'}>Haqqımızda</Link>
                        </li>
                        <li>
                            <Link to={'/contact'}>Əlaqə</Link>
                        </li>
                    </ul>
               </div>
               <div>
                    <h3 className='footer-sub_tit'>Təlimlərimiz</h3>
                    <ul className='footer_nav'>
                        {
                            trainings.map((data, i)=>{
                                return(
                                    <li key={i}>
                                        <Link to={`/training/${data.id}`}>{data?.title}</Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
               </div>
            </div>
        </div>
        <p className='copyRight'>Copyright 2023 | <a target='_blank' href="https://zakirabd.com">Zakir Abdurrahimov</a>. All Rights Reserved</p>
      </footer>
    )
  }
}

export default configRedux(Footer, ['aboutData', 'trainings'])