import React, { Component } from 'react'

export class LeftSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill='#fff'
      >
        <path d="M1.293 12.707a1 1 0 0 1-.216-.325.986.986 0 0 1 0-.764 1 1 0 0 1 .216-.325l8-8a1 1 0 1 1 1.414 1.414L4.414 11H22a1 1 0 0 1 0 2H4.414l6.293 6.293a1 1 0 0 1-1.414 1.414Z" />
      </svg>
    )
  }
}

export default LeftSvg