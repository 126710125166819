import React, { Component } from 'react'

export class TrainingQuestionCard extends Component {
    
  render() {
    const {item, visible, index, changeVisible} = this.props;
    return (
      <div className='training_question_card'>
        <div onClick={()=>visible? changeVisible(999):changeVisible(index)} className='training_question_card_header flex justify-between'>
            <h3>{item?.question}</h3>
            {
              !visible?
              <button>+</button>: <button>-</button>
              
            }
            
        </div>
        {
            visible?
            <div className='dangerous_div' dangerouslySetInnerHTML={{ __html: item?.answer }}></div>
            :null
        }
      </div>
    )
  }
}

export default TrainingQuestionCard
