import React, { Component } from 'react'

export class DownloadSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        viewBox="0 0 32 32"
        fill='#0C45E6'
      >
        <title>{"download-cloud"}</title>
        <path d="M0 16q0 2.912 1.824 5.088T6.4 23.84q.032 0 .032-.032v-.064l.032-.032q.544-1.344 1.344-2.176t2.208-1.184v-2.336q0-2.496 1.728-4.256T16 12t4.256 1.76 1.76 4.256v2.336q1.376.384 2.176 1.216t1.344 2.144l.096.288h.384q2.464 0 4.224-1.76T32 18.016V16q0-2.464-1.76-4.224t-4.224-1.76q-.096 0-.32.032.32-1.152.32-2.048 0-3.296-2.368-5.632T18.016 0q-2.88 0-5.056 1.824t-2.784 4.544Q9.024 6.016 8 6.016q-3.296 0-5.664 2.336T0 14.016V16zm10.016 9.824q-.096.928.576 1.6l4 4Q15.168 32 16 32t1.408-.576l4-4q.672-.672.608-1.6-.064-.32-.16-.576-.224-.576-.736-.896T20 24h-1.984v-5.984q0-.832-.608-1.408T16 16t-1.408.608-.576 1.408V24H12q-.608 0-1.12.352t-.736.896q-.096.288-.128.576z" />
      </svg>
    )
  }
}

export default DownloadSvg
