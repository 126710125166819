import React, { Component } from 'react'

export class PageHeaderSection extends Component {
  render() {
    const {title, titleHtml} = this.props;
    return (
        <section>
            <div className="page-header">
                <div className="shadow flex items-center justify-center">
                  {
                    titleHtml?
                    <h1 className="header-title" dangerouslySetInnerHTML={{ __html: titleHtml }}></h1>:
                    <h1 className="header-title">{title}</h1>
                  }
                    
                </div>
            </div>
        </section>
    )
  }
}

export default PageHeaderSection