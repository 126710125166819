import React, { Component } from 'react'
import TrainerCard from '../cards/TrainerCard'
import SectionHeader from '../SectionHeader'
import { configRedux } from '../../redux/ConfigRedux'
import { getTrainers } from '../../actions/MainAction'

export class TrainersSection extends Component {
  componentDidMount(){
    this.props.dispatch(getTrainers())
  }
  render() {
    const {trainers} = this.props;
    // console.log(trainers)
    return (
        <section>
            <div className="container mt-5">
            <SectionHeader title="Müəllimlərimiz" />
                <div className="grid-col-3">
                  {
                    trainers.map((data, i)=>{
                      return(
                        <TrainerCard 
                          key={i}
                          data={data}
                        />
                      )
                    })
                  }
                </div>
            </div>
        </section>
      
    )
  }
}

export default  configRedux(TrainersSection, ["trainers"])
