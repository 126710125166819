import React, { Component } from 'react'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import TrainingSection from '../components/sections/TrainingSection'
import WhatSayStudents from '../components/sections/WhatSayStudents'
import {Helmet} from "react-helmet";
export class TrainingPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nicode Academy Təlimləri - Nicode Academy</title>
          <meta name="description" content="Nicode Academy-nin təlimləri ilə Data Analitika, Data Science, Python, Excel, SQL, Power BI və digər peşəkar bacarıqları öyrənin. Təcrübəli müəllimlər tərəfindən tədris olunan kurslarımız, müasir texnologiyalar və real layihələr üzərində praktiki təlimlər təklif edir.
"/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content="Nicode Academy-nin təlimləri ilə Data Analitika, Data Science, Python, Excel, SQL, Power BI və digər peşəkar bacarıqları öyrənin. Təcrübəli müəllimlər tərəfindən tədris olunan kurslarımız, müasir texnologiyalar və real layihələr üzərində praktiki təlimlər təklif edir.
"/>
          <meta name="keywords" content="Data analitika təlimi, Data Science kursu, Python təlimi, Excel ilə analiz, SQL ilə data analitika, Power BI təlimi, Data Science üçün statistika, Python ilə Data Science, təlim kursları, ofis proqramları öyrənmək, Nicode Academy təlimləri" />
          <link rel="canonical" href="https://nicode.az/"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content="Nicode Academy Təlimləri - Nicode Academy"/>
          <meta property="og:title" content="Nicode Academy Təlimləri - Nicode Academy"/>
          <meta property="og:url" content="https://nicode.az/trainings"/>
          <meta property="og:site_name" content="https://nicode.az/trainings"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az/trainings"/>
          <meta property="og:title" content="Nicode Academy Təlimləri - Nicode Academy"/>
          <meta property="og:description" content="Nicode Academy-nin təlimləri ilə Data Analitika, Data Science, Python, Excel, SQL, Power BI və digər peşəkar bacarıqları öyrənin. Təcrübəli müəllimlər tərəfindən tədris olunan kurslarımız, müasir texnologiyalar və real layihələr üzərində praktiki təlimlər təklif edir.
"/>
        </Helmet> 
        <PageHeaderSection title="Təlimlərimiz" />
        <TrainingSection />
        <WhatSayStudents />
        {/* last */}
      </div>
    )
  }
}

export default TrainingPage