import React, { Component } from 'react'
import PlusSvg from '../svgs/PlusSvg';
import MinusSvg from '../svgs/MinusSvg';

export class FaqCard extends Component {
  state={
    visible: false
  }
  render() {
    const {data, visible, index, changeVisible} = this.props;
    return (
        <div className="faq_card">
            <div onClick={()=>changeVisible(visible?100: index)} className="faq-header flex justify-between items-center">
                <h3>{data?.title}</h3>
                {visible?<button><MinusSvg /></button>:<button><PlusSvg /></button>}
                
            </div>
            {
               visible?
              <p className="faq-body" dangerouslySetInnerHTML={{ __html: data?.description }}></p>:null
            }
            
        </div>
    )
  }
}

export default FaqCard